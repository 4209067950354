import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from '@/i18n';
import {
    Image as VanImage, Button, Search,
    Tabbar, TabbarItem, NavBar, Swipe, SwipeItem,
    Col, Row, Grid, GridItem, Step, Steps,
    Popup, Toast, Dialog, PasswordInput, NumberKeyboard,
    Field, CellGroup, Form, Space,
    Empty, Divider, Notify, Loading, Overlay, PullRefresh
} from 'vant'
import 'vant/lib/index.css'

// Create a new App instance.
const app = createApp(App)

// 引用vant components
const vantComponents = [
    VanImage, Button, Search,
    Tabbar, TabbarItem, NavBar, Swipe, SwipeItem,
    Col, Row, Grid, GridItem, Step, Steps,
    Popup, Toast, Dialog, PasswordInput, NumberKeyboard,
    Field, CellGroup, Form, Space,
    Empty, Divider, Notify, Loading, Overlay, PullRefresh
]

vantComponents.forEach((item) => {
    app.use(item)
})

// 引用i18n
app.use(i18n)
// 引用路由
app.use(router)
// 引用vuex store
app.use(store)
app.mount('#app')
