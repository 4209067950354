import { Store } from 'vuex'


const store = new Store({
    state: {
        pageTitle: 'default',   // the title of current page
        pageDepth: 0,           // the depth of current page
        isLoading: false,       // loading indicator
        isFullScreen: false,    // full screen indicator
    },
    getters: {
        /**
         * Retrieves the page title from the given state object.
         *
         * @param {object} state - the state object
         * @return {string} the page title
         */
        getPageTitle: (state) => {
            return state.pageTitle
        },
        /**
         * Get the page depth from the state.
         *
         * @param {Object} state - The state object
         * @return {number} The page depth
         */
        getPageDepth: (state) => {
            return state.pageDepth
        },
        /**
         * Check if the page depth is zero.
         *
         * @param {object} state - the state object
         * @return {boolean} true if the page depth is zero, false otherwise
         */
        isPageDepthZero: (state) => {
            return state.pageDepth === 0
        },
        /**
         * Check if the state is currently loading.
         *
         * @param {object} state - the state object
         * @return {boolean} the loading status
         */
        isLoading: (state) => {
            return state.isLoading
        },
        /**
         * Check if the state is in full screen mode.
         *
         * @param {Object} state - the state object
         * @return {boolean} the value indicating if the state is in full screen mode
         */
        isFullScreen: (state) => {
            return state.isFullScreen
        }
    },
    mutations: {
        /**
         * Set the page title in the state.
         *
         * @param {Object} state - the state object
         * @param {Object} payload - the payload containing the title
         * @return {void}
         */
        setPageTitle(state, payload) {
            state.pageTitle = payload.title
        },
        /**
         * Increases the page depth in the given state.
         *
         * @param {Object} state - the state object to modify
         * @return {void}
         */
        addPageDepth(state) {
            state.pageDepth += 1
        },
        /**
         * Reduces the page depth in the given state.
         *
         * @param {Object} state - the state object
         * @return {void}
         */
        reducePageDepth(state) {
            state.pageDepth -= 1
        },
        /**
         * Clear the page depth in the given state.
         *
         * @param {Object} state - the state object
         * @return {undefined}
         */
        clearPageDepth(state) {
            state.pageDepth = 0
        },
        /**
         * showLoading - A function to set the isLoading state to true.
         *
         * @param {object} state - The state object
         */
        showLoading(state) {
            state.isLoading = true
        },
        /**
         * Dismisses the loading state.
         *
         * @param {object} state - the state object
         * @return {void}
         */
        dismissLoading(state) {
            state.isLoading = false
        },
        /**
         * Set the full screen state.
         *
         * @param {type} state - description of state parameter
         * @param {type} flag - description of flag parameter
         * @return {type}
         */
        setFullScreen(state, flag) {
            state.isFullScreen = flag
        }
    }
})

export default store
