
import { colog } from 'colog-node';
import { createRouter, createWebHashHistory } from 'vue-router'
import { useStore } from "vuex";

const routes = [
    {
        path: '/',
        name: 'HomeView',
        component: () => import('../views/home/HomeView.vue')
    }, {
        path: '/merchant',
        redirect: '/merchant/list',
        children: [
            {
                path: 'list',
                name: 'MerchantListView',
                component: () => import('../views/merchant/MerchantListView.vue')
            }, {
                path: 'stamp/list',
                name: 'MerchantStampListView',
                component: () => import('../views/stamp/StampListView.vue')
            }
        ]
    }, {
        path: '/stamp',
        redirect: '/stamp/list',
        meta: { requiresAuth: true },
        children: [
            {
                path: 'list',
                name: 'StampListView',
                component: () => import('../views/stamp/StampListView.vue')
            },
            {
                path: 'coll/:id',
                name: 'StampCollView',
                component: () => import('../views/stamp/StampCollView.vue')
            }
        ]
    },
    {
        path: "/login",
        name: "LoginView",
        meta: { requiresAuth: false },
        component: () => import("../views/auth/LoginView.vue"),
    },
    {
        path: '/login/email',
        name: 'LoginByEmailView',
        component: () => import('../views/auth/LoginByEmailView.vue')
    },
    {
        path: "/:pathMatch(.*)*",
        name: "NotFoundView",
        component: () => import("../views/404/NotFoundView.vue"),
    }
]


const router = createRouter({
    history: createWebHashHistory(),
    routes
})

// MARK - 路由守卫
// ************************************************************************
let lastPosition = 0
router.beforeEach((to, from, next) => {
    // 解决刷新后页面不会回到顶部的问题
    document.body.scrollTop            = 0;  // firefox
    document.documentElement.scrollTop = 0;  // safari
    window.pageYOffset                 = 0;  // 调用 next()，一定要调用 next 方法，否则钩子就不会被销毁

    // 获取用户登录状态
    const isLogin = false
    if (to.meta.requiresAuth && !isLogin) {
        next({ name: 'LoginView' })
        return
    } else {
        next()
    }
})

router.afterEach((to, from) => {
    const store = useStore()
    const curPosition = window.history.state.position
    if (curPosition > lastPosition) {
        store.commit('addPageDepth')
    }
    if (curPosition < lastPosition) {
        store.commit('reducePageDepth')
    }
    // colog.debug("history position => " + window.history.state.position)
})


export default router
