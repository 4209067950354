import { createI18n } from 'vue-i18n'
import enLocale from './en.json';
import zhLocale from './zh.json';


// 获取浏览器的首选语言
const userLanguage = navigator.language;
// 如果用户语言以 'zh' 开头，则设置为中文，否则设置为英文
let locale = userLanguage.startsWith('zh') ? 'zh' : 'en';
locale = 'zh'

const i18n = createI18n({
    legacy         : false,   // 使用 Composition API
    globalInjection: true,    // 全局注入语言
    locale         : locale,  // 设置语言
    messages       : {
        en: enLocale,
        zh: zhLocale
    }
});


export default i18n
