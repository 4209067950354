<template>
    <div class="app">
        <NavbarCom></NavbarCom>
        <router-view class="content" ref="app"></router-view>
        <TabbarCom></TabbarCom>
        <LoadingBlurCom></LoadingBlurCom>
    </div>
</template>

<script setup>
import router from './router';
import { onMounted, watch } from 'vue';
import TabbarCom from './components/TabbarCom/TabbarCom.vue';
import NavbarCom from './components/NavbarCom/NavbarCom.vue'
import LoadingBlurCom from './components/LoadingBlurCom/LoadingBlurCom.vue'
import forceMobileMode from './utils/forceMobileMode';
import i18n from './i18n'
import { colog } from 'colog-node';
const { t } = i18n.global

// MARK - LIFECYCLE
// ************************************************************************
onMounted(() => {
    forceMobileMode()
    document.title = t('app.title')
})

</script>

<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}

html,
body,
ul,
li,
div,
h1 {
    padding: 0;
    margin: 0;
}

.content {
    /* 空余出Tabbar的高度 */
    padding-bottom: 64px;
    /* margin-bottom: 64px; */
}
</style>
./utils/deiceUtils
